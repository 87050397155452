//PrimarySearchAppBar.jsx
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import logo from '../img/enadoBlack.png';
import logoSlunicko from '../img/ENADO_sl2-50pr.png';
import { useTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { addQuickUkol } from '../api/save';
import Modal from 'react-modal';
import UkolQuickComponent from '../molecules/UkolQuickComponent';
import LinearIndeterminate from './LinearIndeterminate';
import Alert from '@mui/material/Alert';
import { webhook } from '../api/webhook';
import { DataContext } from '../store/baseData';
import dayjs from 'dayjs';
import DataTable from '../organisms/listTableMui1'; // Importujte komponentu DataTable
import { paramsList } from '../api/apiRest';
import { getDataList, checkTaskStatus } from '../api/apiRest';
import { paramsLLimit } from '../api/apiRest';
import { getDataStructurEnt } from '../api/apiRest';
import { set } from 'date-fns';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import CloseIcon from '@mui/icons-material/Close';



export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userAnchorEl, setUserAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalUkolListIsOpen, setModalUkolListIsOpen] = useState(false);
  const [modalUkolListMobilIsOpen, setModalUkolListMobilIsOpen] = useState(false);
  const [modalContactListIsOpen, setModalContactListIsOpen] = useState(false);
  const [modalContactListMobilIsOpen, setModalContactListMobilIsOpen] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [messagewebhook, setMessagewebhook] = useState(null);
  const [severitywebhook, setSeveritywebhook] = useState(null);
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  const [webhookSuccessful, setWebhookSuccessful] = useState(false);
  const navigate = useNavigate();
  const { ukol, setUkol, paramsLAllAll, contactsGlob } = useContext(DataContext);

  const [entityTitle, setEntityTitle] = useState('Načítám data...');
  const [data, setData] = useState([]);      // Data pro zobrazení v listu
  const [headers, setHeaders] = useState([]); // hlavicka tabulky
  const [mjs, setMjs] = useState([]); // jednotky
  const [inputFormat, setInputFormat] = useState([]); // format vstupu [date, number
  const [headersParam, setHeadersParam] = useState([]); // parametry radku
  

  const isMenuOpen = Boolean(anchorEl);
  const isUserMenuOpen = Boolean(userAnchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const theme = useTheme();

  const userEmail = localStorage.getItem('userEmail');
  //const userId = localStorage.getItem('userId');
  const location = useLocation();
  const paramsURL = new URLSearchParams(location.search);
  const tableEntita = paramsURL.get('tableEntita');
  const location1 = location.pathname; // vrátí "/detail-view"
  const page = paramsURL.get('page');
  const idUn = paramsURL.get('idUn') ;
  
  let userUkol
  let userUkolNesplnene
  let userUkolNesplneneLength
  let userUkolKriticke
  let headersContact
  let mjsContact
  let inputFormatContact
  let headersParamContact
  let contactLength
  let contactsQuickReduce

  //console.log('v Header - contactsGlob: ', contactsGlob, ukol);
  //--------AI----------------

 //--------AI----------------
 const user = { userId: '', userEmail: userEmail };
 const context = {page: page, entita: tableEntita, idEntita: idUn}
 //console.log('v Header - user', user);
 //console.log('v Header - context', context);

 useEffect(() => {
  if(userEmail && location1){
    console.log('Loading Voiceflow widget...');
    console.log('Conditions:', { userEmail, location1 });
    (function(d, t) {
      const v = d.createElement(t), s = d.getElementsByTagName(t)[0];
      v.onload = function() {
        window.voiceflow.chat.load({
          verify: { projectID: '677d0d0490b5a05ba96b83de' },
          url: 'https://general-runtime.voiceflow.com',
          versionID: 'production',
          launch: {
            event: {
              type: "launch",
              payload: {
                userEmail: userEmail,
                accessPoint: 'intranet',
                context: {
                  page: location1,
                  entita: tableEntita,
                  idEntita: idUn
                }
                
              }
            }
          }
        }).then(() => {
          console.log('Voiceflow widget úspěšně načten.');
        
          // Odeslání programové zprávy po načtení widgetu
          window.voiceflow.chat.interact({
            type: 'text',
            payload: {
              userEmail: userEmail,
              accessPoint: 'intranet',
              context: {
                page: location1,
                entita: tableEntita,
                idEntita: idUn
              }
            }
          });

          console.log('Zpráva byla programově odeslána.');

        }).catch((error) => {
          console.error('Chyba při načítání Voiceflow widgetu:', error);
        });
      };
      v.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
      v.type = "text/javascript";
      s.parentNode.insertBefore(v, s);
    })(document, 'script');
  }
}, [userEmail, tableEntita, idUn, location1]);

  if (window.voiceflow && window.voiceflow.chat) {
    console.log('Voiceflow widget načten.');
  } else {
    console.error('Voiceflow widget není načten.');
  }
  
  
  //--------konec AI----------------


  //-------nacteni dat pro Contact----------
    
    if (contactsGlob && contactsGlob.length > 0) {
      // Zde můžete provést operace s contactsQuick, když jsou data k dispozici
      headersContact = ['Firemní kod', 'Jméno', 'Příjmení', 'Email', 'Telefonní číslo'];
      mjsContact = ['null', 'null', 'null', 'null', 'null'];
      inputFormatContact = ['auto', 'auto', 'auto', 'auto', 'auto'];
      headersParamContact = ['Code', 'Firstname', 'LastName', 'Email', 'PhoneNumber'];
      contactsQuickReduce = contactsGlob.map(contact => {
        const { Code, Firstname, LastName, Email, PhoneNumber } = contact;
        return { Code, Firstname, LastName, Email, PhoneNumber };
      });
      contactLength = contactsGlob.length;
      //console.log('999999999999 - v header - Contacts ', headersContact, mjsContact, inputFormatContact, headersParamContact, contactLength, contactsQuickReduce);
      
    }
 
     
  //-------konec nacteni dat pro Contact----------
  
  //-------nacteni dat pro Ukol----------


  useEffect(() => {
  
    if(paramsLAllAll){
        const paramsL = paramsLAllAll.filter(obj => obj.tableEntita === 'Ukol');
        const paramsLL = paramsLLimit(paramsL, 'listViewTab');
        //console.log('v Header - paramsLL', paramsLL);
        const headersRes = paramsLL.map(objekt => objekt.vizu);
        const mjsRes = paramsLL.map(objekt => objekt.mj);
        const inputFormatRes = paramsLL.map(objekt => objekt.input);
        const headersParamRes = paramsLL.map(objekt => objekt.parametr);
        //console.log('999999999999 - v header - ', headers, mjs, inputFormat, headersParam);

        setHeaders(headersRes);
        setMjs(mjsRes);
        setInputFormat(inputFormatRes);
        setHeadersParam(headersParamRes);
        //setData(dataList1.data);
        //console.log('v listView dataList', dataList);
    }
  }, [paramsLAllAll]);
 

  if(ukol.length>0){
    //console.log('v PrimarySearchAppBar - ukol', ukol);
    userUkol = ukol.filter((ukol1) => ukol1.resitel === userEmail);
    //console.log('v PrimarySearchAppBar - userUkol', userUkol);
    userUkolNesplnene = userUkol.filter((ukol1) => ukol1.procesUkol !== '03 Done');
    userUkolNesplneneLength = userUkolNesplnene.length;
    //console.log('v PrimarySearchAppBar - userUkolNesplnene', userUkolNesplnene);
    userUkolKriticke = userUkol.filter((ukol1) => dayjs(ukol1.dateDeadline).isBefore(dayjs().add(3, 'day')));
    //console.log('deadline:', userUkol[0].dateDeadline);
    //console.log('dayjs().add(-10, "day"):', dayjs().add(10, 'day'));
    //console.log('v PrimarySearchAppBar - userUkolKriticke', userUkolKriticke);
  }

  //-------konec nacteni dat pro Ukol----------
  

  const openmodalUkolAdd = (event) => {
    // místo otevření nového okna, otevřete modální dialog
    setClickPosition({ x: event.clientX, y: event.clientY });
    setModalIsOpen(true);
  };

  const openmodalUkolList = (event) => {
    // místo otevření nového okna, otevřete modální dialog
    setClickPosition({ x: event.clientX, y: event.clientY });
    setModalUkolListIsOpen(true);
    
  };
  const openmodalUkolListMobil = (event) => {
    // místo otevření nového okna, otevřete modální dialog
    setClickPosition({ x: event.clientX, y: event.clientY });
    setModalUkolListMobilIsOpen(true);
  };

  const openmodalContactList = (event) => {
    // místo otevření nového okna, otevřete modální dialog
    setClickPosition({ x: event.clientX, y: event.clientY });
    setModalContactListIsOpen(true);
    
  };
  const openmodalContactListMobil = (event) => {
    // místo otevření nového okna, otevřete modální dialog
    setClickPosition({ x: event.clientX, y: event.clientY });
    setModalContactListMobilIsOpen(true);
  };

  const handleBodyChange = async (body) => {
    
    //console.log('v PrimarySearchAppBar - body', body);
    const command = 'dupl';
    
    try {

      //-----------------ulozeni dat-------------

      const saved = await addQuickUkol('Ukol', body, command);
      
      //console.log('00000 - Header - addQuickUkol - vraceno z save procesu:', saved)
      if (saved.error) {
          console.error('Error: ', saved.error);
          setMessage(saved.error);
          setSeverity('error');
      } else if(command=='save' && saved) {
          //console.log('kontrola ulozeni: ', saved)
          setMessage(`Uloženo ${saved.insertId}`);
          setSeverity('success');
          setSaveSuccessful(true);
          console.log('v detailView - prepl jsem saveSuccessful', saveSuccessful)
      }else if(command=='dupl' && saved) {
          //console.log('kontrola vduplikace: ', saved)
          setMessage(`Uloženo ${saved.insertId}`);
          setSeverity('success');
          setSaveSuccessful(true);
          
      }

      // Close the alert after 5 seconds
      setTimeout(() => {
          setMessage(null);
      }, 1000);

      //-----------------ulozeni dat-------------

      //-----------------webhook-------------
      if(body.sendEmail){
          setLoading(true);
        const webhookRes = await webhook('Ukol', saved.insertId, '');
        setLoading(false);

        //console.log(webhookRes)
        if (webhookRes.result.success==false || webhookRes.error) {
            console.error('Error: ', webhookRes.result.message, webhookRes.result.success);
            setMessagewebhook(['Chyba:', webhookRes.result.message]);
            setSeveritywebhook('error');
        } else if( webhookRes.result.success==true ){
            console.log('kontrola: ', webhookRes)
            setMessagewebhook([
                `${webhookRes.result.message.MS}`,
                
            ]);
            setSeveritywebhook('success');
            setWebhookSuccessful(true);
            console.log('v detailView - webhookSuccessful', webhookSuccessful)
        } else if(webhookRes.result.success=='NA'){
            console.log('kontrola: ', webhookRes)
            setMessagewebhook(['Oznámení:', webhookRes.result.message]);
            setSeveritywebhook('success');
            setWebhookSuccessful(true);
            // Close the alert after 5 seconds
            setTimeout(() => {
                setMessagewebhook(null);
            }, 1000);
        }
      }
      
      // Close the alert after 3 seconds
      setTimeout(() => {
          setMessagewebhook(null);
          setModalIsOpen(false)
      }, 1000);

      //-----------------webhook-------------



    }
    catch (error) {
      console.error(`Error: ${error.message}`);
      
    }
    
  };

  const handleProfileMenuOpen = (event) => {
    setUserAnchorEl(event.currentTarget);
    
  };
  
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // set anchorEl when MenuIcon is clicked
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setUserAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // Funkce pro přesměrování na detail entity
  const handleEntityClick = (tableEntita, entita, page, id) => {
    console.log('v PrimarySearchAppBar - tableEntita', `/detail-view?tableEntita=${tableEntita}&idUn=${id}&entita=${entita}&page=${page}`);
    setModalUkolListIsOpen(false);
    setModalUkolListMobilIsOpen(false);
    window.open(`/detail-view?tableEntita=${tableEntita}&idUn=${id}&entita=${entita}&page=intranet`);
   
};
    
  const menuId = 'primary-search-account-menu';
  const obecMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}><Link to="/">Domů</Link></MenuItem>
      <MenuItem onClick={handleMenuClose}><Link to="/about">O nás</Link></MenuItem>
      <MenuItem onClick={handleMenuClose}><Link to="/services">Služby</Link></MenuItem>
      <MenuItem onClick={handleMenuClose}><Link to="/contact">Kontakt</Link></MenuItem>
    </Menu>

  );

  const userMenu = (
    <Menu
      anchorEl={userAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isUserMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}><Link to="/logout">logout</Link></MenuItem>
      <MenuItem onClick={handleMenuClose}><Link to="/about">userSetings</Link></MenuItem>
      <MenuItem>{userEmail}</MenuItem>
    </Menu>

  );

  const modalUkolAdd = (
    <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Ukol Modal"
                style={{
                    content: {
                        position: 'absolute',
                        top: `80px`,
                        left: `50%`,
                        transform: 'translate(-50%, -0%)',
                        bottom: 'auto',
                        width: '100%',
                        height: 'auto',
                        maxWidth: '360px',
                        zIndex: 1000,
                        border: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                       
                    }
                }}
            >
                <IconButton 
                  onClick={() => setModalIsOpen(false)} 
                  style={{ 
                    position: 'absolute', 
                    top: 0, 
                    right: 0, 
                    zIndex: 1001 
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {loading && <LinearIndeterminate />}
                {message && (
                    <Alert 
                        variant="outlined" 
                        severity={severity} 
                        style={{ 
                            position: 'absolute', 
                            top: 40, 
                            zIndex: 9999, 
                            height: 50, 
                            width: 250, 
                            overflow: 'auto', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            backgroundColor: 'white' 
                        }}
                    >
                        {message}
                    </Alert>
                )}
                {messagewebhook && (
                    <Alert 
                        variant="outlined" 
                        severity={severitywebhook} 
                        style={{ 
                            position: 'absolute',
                            top: 40,
                            zIndex: 9999, 
                            height: 50, 
                            width: 250, 
                            overflow: 'auto', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            backgroundColor: 'white' 
                        }}
                    >
                        {messagewebhook.map((item, index) => (
                            <React.Fragment key={index}>
                                {item}
                                <br />
                            </React.Fragment>
                        ))}
                    </Alert>
                )}
                <UkolQuickComponent 
                    userEmail={userEmail} 
                    tableEntita={tableEntita} 
                    idUn={idUn} 
                    page={page} 
                    onBodyChange={handleBodyChange}
                />
            </Modal>


  )

  const modalContactList = (
    <Modal
                isOpen={modalContactListIsOpen}
                onRequestClose={() => setModalContactListIsOpen(false)}
                contentLabel="Contact Modal"
                style={{
                    content: {
                        position: 'absolute',
                        top: `80px`,
                        left: `50%`,
                        transform: 'translate(-50%, -0%)',
                        bottom: 'auto',
                        width: 'calc(100vw - 40px)', // 100% of viewport width minus 40px for some padding
                        maxWidth: '1200px', // maximum width of 1200px
                        height: 'auto',
                        zIndex: 1000,
                        border: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }
                }}
            >
              <IconButton 
                  onClick={() => setModalContactListIsOpen(false)} 
                  style={{ 
                    position: 'absolute', 
                    top: 0, 
                    right: 0, 
                    zIndex: 1001 
                  }}
                >
                  <CloseIcon />
              </IconButton>
                {loading && <LinearIndeterminate />}
                
                <DataTable tableEntita='Zamestnanci' pageP={'intranet'} data={contactsQuickReduce} headers={headersContact} columnAccessors={headersParamContact} onRowClick={handleEntityClick} mjs={mjsContact} inputFormat={inputFormatContact} />
            </Modal>

  )

  const modalContactListMobil = (
    <Modal
                isOpen={modalContactListMobilIsOpen}
                onRequestClose={() => setModalContactListMobilIsOpen(false)}
                contentLabel="Contact Modal Mobile"
                style={{
                    content: {
                        position: 'absolute',
                        top: `80px`,
                        left: `50%`,
                        transform: 'translate(-50%, -0%)',
                        bottom: 'auto',
                        width: '100%',
                        height: 'auto',
                        zIndex: 1000,
                        border: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }
                }}
            >
              <IconButton 
                  onClick={() => setModalContactListMobilIsOpen(false)} 
                  style={{ 
                    position: 'absolute', 
                    top: 0, 
                    right: 0, 
                    zIndex: 1001 
                  }}
                >
                  <CloseIcon />
              </IconButton>

                {loading && <LinearIndeterminate />}
                
                <DataTable tableEntita='Zamestnanci' pageP={'intranet'} data={contactsQuickReduce} headers={headersContact} columnAccessors={headersParamContact} onRowClick={handleEntityClick} mjs={mjsContact} inputFormat={inputFormatContact} />
            </Modal>

  )

  const modalUkolList = (
    <Modal
                isOpen={modalUkolListIsOpen}
                onRequestClose={() => setModalUkolListIsOpen(false)}
                contentLabel="Ukol Modal"
                style={{
                    content: {
                        position: 'absolute',
                        top: `80px`,
                        left: `50%`,
                        transform: 'translate(-50%, -0%)',
                        bottom: 'auto',
                        width: 'calc(100vw - 40px)', // 100% of viewport width minus 40px for some padding
                        maxWidth: '1200px', // maximum width of 1200px
                        height: 'auto',
                        zIndex: 1000,
                        border: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }
                }}
            >
              <IconButton 
                  onClick={() => setModalUkolListIsOpen(false)} 
                  style={{ 
                    position: 'absolute', 
                    top: 0, 
                    right: 0, 
                    zIndex: 1001 
                  }}
                >
                  <CloseIcon />
              </IconButton>

                {loading && <LinearIndeterminate />}
                
                <DataTable tableEntita='Ukol' pageP={page} data={userUkolNesplnene} headers={headers} columnAccessors={headersParam} onRowClick={handleEntityClick} mjs={mjs} inputFormat={inputFormat} />
            </Modal>

  )

  const modalUkolListMobil = (
    <Modal
                isOpen={modalUkolListMobilIsOpen}
                onRequestClose={() => setModalUkolListMobilIsOpen(false)}
                contentLabel="Ukol Modal Mobile"
                style={{
                    content: {
                        position: 'absolute',
                        top: `80px`,
                        left: `50%`,
                        transform: 'translate(-50%, -0%)',
                        bottom: 'auto',
                        width: '100%',
                        height: 'auto',
                        zIndex: 1000,
                        border: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }
                }}
            >
              <IconButton 
                  onClick={() => setModalUkolListMobilIsOpen(false)} 
                  style={{ 
                    position: 'absolute', 
                    top: 0, 
                    right: 0, 
                    zIndex: 1001 
                  }}
                >
                  <CloseIcon />
              </IconButton>

                {loading && <LinearIndeterminate />}
                
                <DataTable tableEntita='Ukol' pageP={page} data={userUkolNesplnene} headers={headers} columnAccessors={headersParam} onRowClick={handleEntityClick} mjs={mjs} inputFormat={inputFormat} />
            </Modal>

  )

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      
      <IconButton
              title="Firemní kontakty"
              size="large"
              color="inherit"
              onClick={(event) => { openmodalContactListMobil(event); handleMobileMenuClose();}}
              sx={{ mr: 2, marginLeft: '0px' }}
            >
              
              <Badge badgeContent={contactLength} color="black">
                <PermContactCalendarIcon />
              </Badge>
            </IconButton>
            {modalContactListMobil}

      <IconButton
              title="Vytvořit úkol"
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={(event) => { openmodalUkolAdd(event); handleMobileMenuClose(); }}
              color="inherit"
              sx={{ mr: 2, marginLeft: '20px' }}
            >
              <AddTaskIcon />
      </IconButton>
      
      {modalUkolAdd}

      
        <IconButton
          title="Úkoly"
          size="large"
          color="inherit"
          onClick={(event) => { openmodalUkolListMobil(event); handleMobileMenuClose(); }}
        >
          <Badge badgeContent={userUkolNesplneneLength} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        { modalUkolListMobil}
      {/*
        <IconButton
          title="Nastavení účtu"
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleProfileMenuOpen}
        >
          <AccountCircle />
        </IconButton>
    */}

      
        <IconButton size="large" aria-label="show 4 new mails" color="inherit" title="Logout">
          <Link to="/logout" style={{ color: 'inherit' }}>
            <LogoutIcon />
          </Link>
        </IconButton>
        
    </Menu>
  );

  

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: 'transparent',
          color: 'black',
          borderBottom: `1px solid ${theme.palette.secondary.dark}`,
          boxShadow: 'none',
          '&:hover': {
            borderBottom: `2px solid ${theme.palette.secondary.main}`,
          },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {/*}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2, marginLeft: '0px' }}
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        */}
  
          <div
            className="logoSlunicko"
            style={{
              maxWidth: '100px',
              display: 'flex',
              justifyContent: 'center',
              padding: '0px 0px 0 0px',
            }}
          >
            <Link to="/home">
              <img src={logoSlunicko} alt="LogoSlunicko" width='35' height='35' />
            </Link>
          </div>

          <div
            className="logo"
            style={{
              maxWidth: '100px',
              display: 'flex',
              justifyContent: 'center',
              padding: '0px 0px 0 0px',
              left: `50%`, transform: 'translate(-50%, -0%)', marginLeft: '100px'
            }}
          >
            <Link to="/board-view" style={{ textDecoration: 'none', color: 'black' }}>
              <img src={logo} alt="Logo" width='79.04' height='34.4' />
            </Link>
          </div>
  
          {/*}
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'block', sm: 'block' }, fontWeight: 'bold', left: `50%`, transform: 'translate(-50%, -0%)', marginLeft: '100px'}}
            >
              <Link to="/board-view" style={{ textDecoration: 'none', color: 'black' }}>
                Intranet
              </Link>
            </Typography>
          </Box>
          */}
  
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

          <IconButton
              title="Firemní kontakty"
              size="large"
              color="inherit"
              onClick={(event) => { openmodalContactList(event)}}
              sx={{ mr: 2, marginLeft: '0px' }}
            >
              
              <Badge badgeContent={contactLength} color="black">
                <PermContactCalendarIcon />
              </Badge>
            </IconButton>
            {modalContactList}
          
          <IconButton
              title="Vytvořit úkol"
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={(event) => { openmodalUkolAdd(event)}}
              color="inherit"
              sx={{ mr: 2, marginLeft: '0px' }}
            >
              <AddTaskIcon />
            </IconButton>
            {modalUkolAdd}

            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              title="Úkoly a upozornění"
              onClick={(event) => { openmodalUkolList(event)}}
              sx={{ mr: 2, marginLeft: '0px' }}
            >
              
              <Badge badgeContent={userUkolNesplneneLength} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {modalUkolList}
            {/*
            <IconButton
              title="Nastavení účtu"
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              sx={{ mr: 2, marginLeft: '0px' }}
            >
              <AccountCircle />
            </IconButton>
          */}

            <IconButton size="large" color="inherit" title="Logout">
              <Link to="/logout" style={{ color: 'inherit' }}>
                <LogoutIcon />
              </Link>
            </IconButton>

          </Box>
  
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              sx={{ mr: 2, marginLeft: '0px' }}
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {obecMenu}
      {userMenu}
    </Box>
    
  );
}